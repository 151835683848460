import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./src/styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/noto-serif-sc";

const queryClient = new QueryClient();
export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {element}
    </QueryClientProvider>
  );
};
