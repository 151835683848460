exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-upcoming-tsx": () => import("./../../../src/pages/upcoming.tsx" /* webpackChunkName: "component---src-pages-upcoming-tsx" */),
  "component---src-pages-worklist-tsx": () => import("./../../../src/pages/worklist.tsx" /* webpackChunkName: "component---src-pages-worklist-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

